.ad-container {
  width: 100%;
  max-width: 728px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
}

.ad-link {
  width: 100%;
  display: block;
}

.ad-link img {
  width: 100%;
  height: auto;
  display: block;
} 