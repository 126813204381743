.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #1e3c72, #2a5298);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
  padding: 0.5rem;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 60px;
}

.header-content.mobile.portrait {
  flex-direction: column;
  gap: 0.5rem;
  min-height: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.header-content.mobile.landscape {
  padding: 0.5rem 1rem;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0;
}

.header-content.mobile.portrait .logo {
  position: relative;
  left: auto;
  transform: none;
  order: -1;
  margin-bottom: 0.3rem;
}

.logo-text {
  font-size: 2.25rem;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-transform: uppercase;
}

.header-content.mobile .logo-text {
  font-size: 1.8rem;
}

.logo-image {
  height: 42px;
  margin-left: 9px;
  margin-right: 0px;
}

.header-content.mobile .logo-image {
  height: 32px;
  margin-left: 8px;
}

.edition-selector {
  min-width: 150px;
  display: flex;
  align-items: center;
}

.header-content.mobile.portrait .edition-selector {
  width: auto;
  text-align: left;
}

.edition-select {
  color: #ffffff !important;
  font-size: 0.9rem;
  background: linear-gradient(to bottom, rgba(144, 202, 249, 0.3), rgba(144, 202, 249, 0.2)) !important;
  border-radius: 6px !important;
  padding: 2px 8px !important;
  height: 32px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2),
              inset 0 1px 1px rgba(255, 255, 255, 0.2) !important;
  border: 1px solid rgba(144, 202, 249, 0.4) !important;
  transition: all 0.2s ease-in-out !important;
}

.edition-select:hover {
  background: linear-gradient(to bottom, rgba(144, 202, 249, 0.4), rgba(144, 202, 249, 0.3)) !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3),
              inset 0 1px 1px rgba(255, 255, 255, 0.3) !important;
  transform: translateY(-1px);
}

.edition-select:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2),
              inset 0 1px 1px rgba(255, 255, 255, 0.1) !important;
}

.edition-select .MuiSelect-icon {
  color: #90caf9;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.edition-select::before,
.edition-select::after {
  border-color: #90caf9 !important;
}

.edition-select .MuiSelect-select {
  height: 32px !important;
  line-height: 32px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header-content.mobile .edition-select {
  height: 28px !important;
  font-size: 0.8rem;
}

.right-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.header-content.mobile.portrait .right-info {
  align-items: flex-end;
  text-align: right;
  width: auto;
}

.header-content.mobile .right-info {
  gap: 2px;
}

.date {
  font-size: 0.9rem;
  color: #ffffff;
  opacity: 0.9;
  font-weight: bold;
}

.last-update {
  font-size: 0.9rem;
  color: #ffffff;
  opacity: 0.9;
}

.header-content.mobile .date,
.header-content.mobile .last-update {
  font-size: 0.8rem;
}

/* Override Material-UI Select styles */
.MuiSelect-select {
  color: #ffffff !important;
}

.MuiMenuItem-root {
  font-size: 0.9rem;
}

/* Info row */
.header-content.mobile.portrait .info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
}

/* Mobile landscape logo */
.header-content.mobile.landscape .logo-text {
  font-size: 2.25rem;
}

.header-content.mobile.landscape .logo-image {
  height: 42px;
  margin-left: 9px;
} 